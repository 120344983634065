import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  TextInput,
  RadioButton,
  Button,
  Checkbox,
  RadioButtonGroup,
  Stack,
  Select,
  FormLabel,
  SelectItem,
  DatePicker,
  DatePickerInput,
  MultiSelect,
  NumberInput,
  Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction
} from '@carbon/react';
import languages from './languages.js';
import { CreateRelease, PostUploads } from './UploadServices';
import { FileUploader, FileUploaderDropContainer, FileUploaderItem, SideNav, SideNavLink, SideNavMenu, SideNavItems, SideNavMenuItem, FileUploaderButton } from '@carbon/react';
import { getS3Form, postFileToS3 } from './s3Services';
import { ImageFile } from './ImageFile';
import { Home, Search, Compass, Radio, Music, User, CenterCircle, Playlist, Upload, UserAvatar } from '@carbon/icons-react'; // Import the home icon

import "./UploadForm.css";
import logo from './images/logo.svg'
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

const genres = [
  {
    "text": "metal"
  },
  {
    "text": "country"
  },
  {
    "text": "hip hop"
  }
]

const DraggableFileUploaderItem = ({ fileItem, index, onTitleChange, onDelete }) => {
  return (
    <Draggable draggableId={fileItem.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="file-item" // Use this class for styling
        >

          <div className="file-header">
            <div className="file-item-index">{index + 1}.</div>
            <TextInput
              id={`title-input-${index}`}
              labelText=""
              placeholder="Title"
              value={fileItem.title}
              size="md"
              onChange={(e) => onTitleChange(index, e.target.value)}
              className="file-item-title"
            />
          </div>
          <TextInput
            id={`file-name-${index}`}
            labelText=""
            value={fileItem.file.name}
            disabled
            className="file-item-filename"
          />
          <Button kind="secondary" size="small" onClick={() => onDelete(index)}>Delete File</Button>
        </div>
      )}
    </Draggable>
  );
};

// TODO: use functional not oo
function UploadForm() {

  let navigate = useNavigate()
  const [state, setState] = useState({stores: [], dragging: false, uploadedFiles: new Set(), files: []});
  const [cookies] = useCookies(['csrftoken']); // Specify the cookies you're interested in
  const [usersArtists, setUsersArtists] = useState([]);
  // Access the CSRF token directly from the cookies object
  const csrftoken = cookies.csrftoken;
  const [files, setFiles] = useState([]);
  const [chosenArtistsId, setChosenArtistsId] = useState(-1);
  const [image, setImage] = useState([]);
  const [releaseName, setReleaseName] = useState("");
  const [imgTag, setImgTag] = useState("");
  const [previewImageUri, setPreviewImageUri] = useState([]);
  useEffect(() => {
      // Replace the URL with your actual endpoint
    const fetchUsersArtists = async () => {

      const response =  await fetch('/Apis/get_artists');
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const transformedData = parsedData.map(artist => ({
                    id: artist.pk, // 'pk' is used as the id
                    href: `/EditArtist/${artist.pk}`,
                    artistName: artist.fields.name // Extract 'name' from the 'fields' object
                }));
      setUsersArtists(transformedData);
    }

    fetchUsersArtists();
    //console.log(options);
  }, []);

  const handleReleaseNameChange = (event) => {
    setReleaseName(event.target.value);
  };

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files).map(file => ({
      id: uuidv4(),
      file,
      title: file.name.split('.')[0]  // Defaulting the title to the file name without extension
    }));


    setFiles(prevFiles => [...prevFiles, ...newFiles]);
    console.log(newFiles);
  };


  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setImage(imageFile);
    readURI(event);

  };

  useEffect(() => {
    setImgTag((
      <div>
        <img 
            className="thumbnail" 
            src={previewImageUri} 
            style={{ width: '10em' }}
        />
      </div>
    ));

  }, [previewImageUri]);

  const readURI = async (e) => {
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        setPreviewImageUri(ev.target.result);
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }


  const handleTitleChange = (index, newTitle) => {
    const updatedFiles = files.map((item, i) => {
      if (i === index) {
        // Spread the properties of the item and overwrite the title
        return { id: item.id, file:item.file, title: newTitle };
      }
      return item;
    });
    setFiles(updatedFiles);
  };
  
  let handleSubmit = async (e) => {
    e.preventDefault();
    // Add your sign-in logic here, e.g., authentication with API or backend

    try {
      var form = new FormData();
      console.log(state.files);
      for (const file of files) {
        console.log(file);
        form.append('files', file.file); // 'files' is the name of the input
        form.append('titles', file.title); // 'files' is the name of the input
      }

      form.append('artistId', chosenArtistsId);
      form.append('artwork', image);
      form.append('releaseName', releaseName);
    console.log(state.files);
    // Make the HTTP request using fetch or axios

    const response = await fetch('/Apis/upload_song', {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrftoken,  // Use the CSRF token from the cookie
        },
        body: form,
    });


  // Handle the response (e.g., show success message, redirect, etc.)
  if (response.ok) {
      console.log('User logged in successfully');
      navigate('/main');
  } else {
      console.log(response);
      console.error('upload failed');
      setShowLoginFailed(true);
  }
  } catch (error) {
      console.error('Error during login:', error);
      setShowLoginFailed(true);
  }

};

  let componentDidMount = () =>  {
  }

  let handleDragOver = (e) => {
    e.preventDefault();
    setState({ dragging: true });
    const files = e.dataTransfer.files;
    console.log(files);
  }

  let handleDragLeave = (e) =>{
    e.preventDefault();
    setState({ dragging: false });
    const files = e.dataTransfer.files;
    console.log(files);
  }

  let handleDrop = (e) =>{
    const files = e.dataTransfer.files;
    console.log(files);
    e.preventDefault();
    setState({ dragging: false });
    // Handle the dropped file here
  }


  const onDragEnd = (result) => {
    console.log("dragend !!!");
    if (!result.destination) return;
    console.log("order changed");
    const items = Array.from(files);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFiles([...items]);
  };

  const onDelete = (index) => {
    console.log("DELETING!!!");
    console.log(index);
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleArtistPick = (event) => {
    setChosenArtistsId(event.target.value);
  };

    return (
      <div className="upload-container">
      <Form onSubmit={handleSubmit} className="upload">
       <Stack gap={7}>
          <FormGroup legendText="">
            <div className="form-container">

              <div>
                <h2 className="formHeader"> Release Information</h2>
                <div className="formOptions">
                  <Select
                      id="select-1"
                      defaultValue="placeholder-item"
                      labelText="Primary Artist Name"
                      className="primaryArtistSelect"
                      onChange={handleArtistPick}
                      size="lg">
                      <SelectItem
                        disabled
                        hidden
                        value="placeholder-item"
                        text="Choose an Artist"
                        size="small"
                        />
                      {usersArtists.map(artist => (
                        <SelectItem value={artist.id} text={artist.artistName}/>
                      ))}
                  </Select>
                  <TextInput className="release-name" id="release-name" onChange={handleReleaseNameChange} labelText="Release Name" placeholder="Release Name"/>   

                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Release Type"
                    className="releaseType"
                    size="lg">
                    <SelectItem
                        disabled
                        hidden
                        value="placeholder-item"
                        text="Choose an option"
                        size="small"
                        />
                    <SelectItem value="single" text="Single" />
                    <SelectItem value="ep" text="EP" />
                    <SelectItem value="lp" text="LP" />
                  </Select>
                  <RadioButtonGroup name="radio-button-group" className="radio-button-group">
                    <RadioButton labelText="New Release" value="new-release" id="radio-1" />
                    <RadioButton labelText="Previous Release" value="previous-release" id="radio-2" />
                  </RadioButtonGroup>
                  <DatePicker datePickerType="simple" className="release-date">
                    <DatePickerInput
                      placeholder="mm/dd/yyyy"
                      labelText="Release Date"
                      id="date-picker-simple"
                    />
                  </DatePicker>
                  <NumberInput
                    iconDescription="Number of Tracks"
                    id="4"
                    label="Tracks"
                    min={1}
                    value={1}
                    className="trackNumberInput"
                  />
              <Checkbox labelText={`Allow Pre-Orders`} id="checkbox-label-1" />
              <div>
                <p>
                  Album Artwork
                </p>
                <FileUploaderButton
                    labelText={"Upload"}
                    buttonKind={'primary'}
                    onChange={handleImageUpload}
                    disableLabelChanges
                />
                {imgTag}
              </div>

              <MultiSelect
               label="Choose an option"
               id="primary-genres"
               titleText="Primary Genre"
               className="genre-select"
               items={genres}
               itemToString={(item) => (item ? item.text : '')}
               selectionFeedback="fixed"
              />

              <MultiSelect
               label="Choose an option"
               id="secondary-genres"
               titleText="Secondary Genres"
               className="genre-select"
               items={genres}
               itemToString={(item) => (item ? item.text : '')}
               selectionFeedback="fixed"
              />
            </div>
            </div>

        </div>

          <Button className="submitButton" type="submit" onClick={handleSubmit}>Submit</Button>
          </FormGroup>
       </Stack>
       </Form>
      <div className="songs-upload-container">
        <h2> Track Information </h2>
        <FileUploaderDropContainer
          style={{ color: '#8F7EFB' }}
          innerRef={{
            current: '[Circular]'
          }}
          labelText="Drag and drop files here or click to upload"
          multiple
          name=""
          onAddFiles={handleFileUpload.bind(this)}
          onChange={handleFileUpload.bind(this)}
          tabIndex={0}
        />

        <DragDropContext onDragEnd={onDragEnd} className="dropcontext">
          <Droppable droppableId="files">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {files.map((file, index) => (
                  <DraggableFileUploaderItem key={file.id} onDelete={onDelete} fileItem={file} onTitleChange={handleTitleChange} index={index} />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

       </div>
    );
}

export default UploadForm;
