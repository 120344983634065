import React from 'react';
import { getS3Form, postFileToS3 } from './s3Services'

import { PostUploads } from './UploadServices'
import { FileUploaderButton } from '@carbon/react';

export class ImageFile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      imageURI : null
    }
  }
  
  buildImgTag(){
    let imgTag = null;
    if (this.state.imageURI !== null)
        imgTag = (
            <div>
            <img 
                className="thumbnail" 
                src={this.state.imageURI} 
                style={{ width: '10em' }}
            />
            </div>
       );
    return imgTag;
  }

  readURI(e){
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        this.setState({imageURI:ev.target.result});
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  handleChange(e){
    this.readURI(e);
    const imageFile = e.target.files[0];
    const body = {
        size: imageFile.size,
        file_name: imageFile.name,
        content_type: imageFile.type,
        field_name: "file"
    };
    // PostUploads(body).then(result => {
    //     const s3Form = getS3Form(imageFile, result["s3_form_data"]["form_inputs"]);
    //     const res = postFileToS3(result["s3_form_data"]["form_url"], s3Form);
    // });
    if (this.props.onChange !== undefined)
      this.props.onChange(e);
  }

  render() {
    const imgTag = this.buildImgTag();
    return <div>
            <p>
              Album Artwork
            </p>
            <FileUploaderButton
                labelText={"Upload"}
                buttonKind={'primary'}
                onChange={this.handleChange.bind(this)}
                disableLabelChanges
            />
            {imgTag}
          </div>;
  }
}