import React, { useRef, useEffect, useState }  from 'react';
import { Outlet, Routes, Route, Link } from 'react-router-dom';
import {SideNav, SideNavItems, SideNavLink, SideNavDivider, SideNavMenu, SideNavMenuItem, Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction, ProgressBar, Modal  } from '@carbon/react';
import logo from './images/logo.svg'
import { Home, Search, Compass, Radio, Music, User, CenterCircle, Playlist, Upload, UserAvatar, PlayFilledAlt, PauseFilled, SkipForwardFilled, SkipBackFilled, MediaLibrary, UserProfile } from '@carbon/icons-react'; // Import the home icon
import shaka from 'shaka-player';
import AudioPlayer from "./AudioPlayer.js"
import './Layout.css'
import Main from './Main';
import CreateArtist from "./CreateArtist"
import CreatePlaylist from "./CreatePlaylist";
import { fetchAuthSession } from '@aws-amplify/auth';

function Layout({ children }) {
  const [options, setOptions] = useState([]);
  const [currentSong, setCurrentSong] = useState({});
  const [sideNavWidth, setSideNavWidth] = useState("20vw"); // Initial width
  const [contentWidth, setContentWidth] = useState('80vw'); // Initial content width calculation
  const [showCreatePlaylist, setShowCreatePlaylist] = useState(false);
  const sideNavRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [bearerToken, setBearerToken] = useState(null);


  const toggleShowCreatePlaylist = () => {
    setShowCreatePlaylist(!showCreatePlaylist);
  };


  const handleMouseDown = (e) => {
    // Consider a 10px area as the "border" for resizing
    const threshold = 10;
    const sideNavRightEdge = sideNavRef.current.getBoundingClientRect().right;
    const offsetRight = Math.abs(sideNavRightEdge - e.clientX);

    if (offsetRight <= threshold) {
      setIsResizing(true);
      // Prevents text selection while dragging
      e.preventDefault();
    }
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      // Calculate new width based on cursor position
      const newWidth = e.clientX - sideNavRef.current.getBoundingClientRect().left;
      setSideNavWidth(newWidth);
      const newContentWidth = `calc(100vw - ${newWidth}px)`;
      console.log(newContentWidth);
      setContentWidth(newContentWidth);
    }
  };

  const handleMouseUp = () => {
    if (isResizing) {
      setIsResizing(false);
    }
  };

  useEffect(() => {
    const sideNavElement = sideNavRef.current;
    sideNavElement.addEventListener('mousemove', updateCursor);

    return () => {
      sideNavElement.removeEventListener('mousemove', updateCursor);
    };
  }, []);



  const updateCursor = (e) => {
    const threshold = 10; // Area considered as the border for resizing
    const sideNavRightEdge = sideNavRef.current.getBoundingClientRect().right;
    const offsetRight = Math.abs(sideNavRightEdge - e.clientX);

    // Change cursor style when within the threshold of the border
    if (offsetRight <= threshold) {
      sideNavRef.current.style.cursor = 'grab';
    } else {
      sideNavRef.current.style.cursor = 'default';
    }
  };

  const setCurrentSongFunction = async (newSong) => {
    setCurrentSong(newSong);
    console.log(newSong.mpd_file_url);
  }

  const fetchBearerToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();
      setBearerToken(token);
    } catch (error) {
      console.error("Failed to fetch token", error);
    }
  };

  useEffect(() => {
    fetchBearerToken();
  }, []);

  useEffect(() => {
      // Replace the URL with your actual endpoint
    console.log("in use effect again");
    const fetchUsersArtists = async () => {
      console.log(bearerToken);
      const response = await fetch('/Apis/get_artists', {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const transformedData = parsedData.map(artist => ({
                    id: artist.pk, // 'pk' is used as the id
                    href: `EditArtist/${artist.pk}`,
                    label: artist.fields.name // Extract 'name' from the 'fields' object
                }));
      setOptions(transformedData);
      console.log(transformedData);
    }


    const fetchUsersPlaylists = async () => {

      const response = await fetch('/Apis/get_playlists', {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const data = await response.json();
      console.log(data);
      if (Array.isArray(data)) {
        setPlaylists(data);
      } else {
        console.error("Expected an array but got:", data);
        setPlaylists([]);
      }
    }

    fetchUsersArtists();
    fetchUsersPlaylists();
    //console.log(options);
  }, [bearerToken]);


  React.useEffect(() => {
    console.log("RESIZING HAPPENING");
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    // Cleanup function to remove global event listeners
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  const handleSliderChange = (newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  const handleSliderChange2 = (event) => {
    const fraction = parseFloat(event.target.value);
    const newTime = fraction * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const calculateProgress = () => {
    return duration > 0 ? currentTime / duration : 0;
  };

  const dynamicItems = [
    { id: 1, label: 'Dynamic Option 1', href: '/DynamicOption1' },
    { id: 2, label: 'Dynamic Option 2', href: '/DynamicOption2' },
    // Add more dynamic items as needed
  ];
  return (
    <div className="app-layout">

    <div>
      <Header className="header" aria-label="Kaze">
        <HeaderName element={Link} to="/main/" prefix="">
          <img
          src={logo} 
          alt="Logo"
          />
        </HeaderName>
        <HeaderGlobalBar aria-label="My Application Navigation" isOverflowMenuVisible={false}>
          <Link to="upload" style={{ textDecoration: 'none' }}>
            <HeaderGlobalAction aria-label="Upload" size="large" className="globalHeaderUpload" ><Upload/></HeaderGlobalAction>
          </Link>
          <HeaderGlobalAction aria-label="Search" href="#"><Search /></HeaderGlobalAction>
          <HeaderGlobalAction aria-label="Settings" href="#"><UserAvatar /></HeaderGlobalAction>
        </HeaderGlobalBar>
        {/* Additional components and features can be added here */}
      </Header>
      </div>

    <div className="layout-controls-and-content" style={{width: '100vw'}}>
        <div className="side-nav-container" ref={sideNavRef} onMouseDown={handleMouseDown} style={{ width: sideNavWidth, position: 'relative', userSelect: 'none' }}>
           <SideNav class="side-nav" aria-label="Side navigation" expanded={true}>
            <SideNavItems className="side-nav-items">
              <SideNavLink renderIcon={Home} element={Link} to="/Main">Home</SideNavLink>
              <SideNavLink element={Link} to="/Main" renderIcon={Compass}>Discover</SideNavLink>
              <SideNavLink element={Link} to="/Main" renderIcon={Radio}>Radio</SideNavLink>
              <SideNavDivider /> 
              <SideNavMenu renderIcon={Playlist} title="Playlists" expanded={true}>
                {playlists.map(playlist => (
                  <SideNavMenuItem key={playlist.id}>
                    {playlist.playlist_name}
                  </SideNavMenuItem>
                ))}
                <SideNavMenuItem className="add-playlist" key="stuff" onClick={toggleShowCreatePlaylist}> 
                  Create a new playlist
                </SideNavMenuItem>
              </SideNavMenu>
              <SideNavDivider /> 

            <SideNavMenu renderIcon={MediaLibrary} title="Media Management" >
              <SideNavMenuItem className="mediamanagementitem" element={Link} to="/main/MyMedia">My Media</SideNavMenuItem>
              <SideNavMenuItem className="mediamanagementitem" element={Link} to="/main/MyArtists">Artists</SideNavMenuItem>

            </SideNavMenu>
            </SideNavItems>
          </SideNav>
        </div>

        <div className="content-and-audioplayer" style={{width: contentWidth, position: 'relative', userSelect: 'none'}}>
          <div className="content" style={{width: contentWidth, position: 'relative', userSelect: 'none'}}>
              <Outlet context={{setCurrentSong}}/>
              <Modal
                open={showCreatePlaylist}
                onRequestClose={toggleShowCreatePlaylist}
              >
                <CreatePlaylist/>
            </Modal>
          </div>
          <div>
              <AudioPlayer className="audio-player" song={currentSong}/>
          </div>
        </div>

      </div>
    </div>

  );
}

// asdfasfd"{React.cloneElement(children, { setCurrentSong: setCurrentSongFunction })}"
//asdfadfs

export default Layout;