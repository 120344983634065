import { Amplify } from 'aws-amplify';
import React, { useEffect } from 'react';
import { Authenticator, useAuthenticator, Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import awsConfig from './aws-exports'; // Ensure this is your configuration file
import './SignInForm.css'; // Ensure this contains your custom styles
import logo from './images/logo.svg';

// Configure Amplify with your AWS configurations
Amplify.configure(awsConfig);

const SignInPage = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  useEffect(() => {
    if (route === 'authenticated') {
      navigate('/'); // Redirect to home if already signed in
    }
  }, [route, navigate]);

  return (
    <div className="login-wrap">
      <div className="login-form">
        <img src={logo} alt="Logo" className="login-logo" />
        <Authenticator>
          {({ signOut }) => (
            <div>
              <h1>Sign in to Kaze</h1>
              <Button onClick={signOut}>Sign Out</Button>
            </div>
          )}
        </Authenticator>
      </div>
      <footer className="footer-container">
        ©2024 Kaze. All Rights Reserved.
        <div className="footer-links">
          <a className="privacy-policy" href="#">Privacy Policy</a>
          <a className="terms-of-service" href="/Register">Terms of Service</a>
          <a className="contact-us" href="/Register">Contact us</a>
        </div>
      </footer>
    </div>
  );
};

export default SignInPage;
