import React, { useRef, useEffect, useState }  from 'react';
import {SideNav, SideNavItems, SideNavLink, SideNavDivider, SideNavMenu, SideNavMenuItem, Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction, ProgressBar, Slider, Link  } from '@carbon/react';
import logo from './images/logo.svg'
import { Home, Search, Compass, Radio, Music, User, CenterCircle, Playlist, Upload, UserAvatar, PlayFilledAlt, PauseFilled, SkipForwardFilled, SkipBackFilled, Shuffle, Repeat} from '@carbon/icons-react'; // Import the home icon
import shaka from 'shaka-player';
import "./AudioPlayer.css";
import { useCookies } from 'react-cookie';

function AudioPlayer({song}) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progressBarRef = useRef(null);
  const scrubberRef = useRef(null);
  const [cookies] = useCookies(['csrftoken']);
  const currentSong = useRef(1);
  const [shakaPlayer, setShakaPlayer] = useState(null);
  useEffect(() => {
  console.log("audo player GETTING MOUNTED AGAIN>>>>>>>");
  // Check if Shaka Player is supported
  if (shaka.Player.isBrowserSupported()) {
    // Instantiate a new player
    const player = new shaka.Player(audioRef.current);
    setShakaPlayer(player);
    console.log(song);
    // Load the manifest URL of your media
    //player.load('https://dash.akamaized.net/dash264/TestCasesUHD/2b/11/MultiRate.mpd').catch(console.error);
    // player.load().catch(console.error);
    if (currentSong.current) {
      player.load(song.mpd_file_url).then(() => {
        console.log('The song has been successfully loaded with manifest:', song);

      }).catch((error) => {
        console.error('Error loading the manifest:', error);
      });
    } 

  } else {
    console.error('Browser not supported for Shaka Player');
  }
  
  //  return () => {
  //   if (player) {
  //     player.destroy(); // Ensure the player is properly destroyed
  //   }
  //   audioRef.current.removeEventListener('ended', getNextSong);
  // };
}, []);


  //this useEffect is called when react state management updates the shakaplayer
  useEffect(() => {
    audioRef.current.addEventListener('timeupdate', () => {
      setCurrentTime(prevCurrentTime => {
        return audioRef.current.currentTime;
      });
      setRemainingTime(audioRef.current.duration-audioRef.current.currentTime);
    });

    audioRef.current.addEventListener('ended', getNextSong);

    audioRef.current.addEventListener('loadedmetadata', () => {
      setDuration(audioRef.current.duration);
      setRemainingTime(audioRef.current.duration);
    });

  }, [shakaPlayer]);


    useEffect(() => {
      currentSong.current = song.mpd_file_url;
      changeLoadedSongToCurrentSong();
    }, [song]);


  const changeLoadedSongToCurrentSong = () => {
    if (currentSong.current && shakaPlayer) {
      console.log("about to load mpd");
      shakaPlayer.load(currentSong.current).then(() => {
        console.log('The song has been successfully loaded with manifest:', song);
        audioRef.current.play().then(() => {setIsPlaying(true)}).catch((error) => {
          console.error('Error auto-playing the song:', error);
          // Handle auto-play failure (e.g., due to browser policies)
        });

      }).catch((error) => {
        console.error('Error loading the manifest:', error);
      });

    }  else {
      console.error('Browser not supported for Shaka Player');
    }
  }


  const calculateProgress = () => {
    return duration > 0 ? currentTime / duration : 0;
  };

  let remainingSeconds;
  if (remainingTime%60 < 10) {
    remainingSeconds = <p>0{Math.floor(remainingTime%60)}</p>;
  }
  else {
    remainingSeconds = <p>{Math.floor(remainingTime%60)}</p>;
  }

const convertSecondsToDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format the duration as HH:MM:SS
    const formattedDuration = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    
    return formattedDuration;
}

const getNextSong = async () => {
      try {
      // Make the HTTP request using fetch or axios
      const response = await fetch('/Apis/get_next_song', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
          },
      });

      // Handle the response (e.g., show success message, redirect, etc.)
      if (response.ok) {
        //console.log((await (response.json())).next_song);
        //setCurrentSong((await response.json()).next_song);
        currentSong.current = (await response.json()).next_song_mpd_file_url;
        console.log( currentSong.current );
        //console.log((await response.json()).next_song_mpd_file_url);

        changeLoadedSongToCurrentSong();
        //handlePlayPause();
      } else {
        console.error('unable to retrieve next song');
      }
    } catch (error) {
        console.error('Error during next song retrieval:', error);
    }
}


  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };


  const handleSeek = (event) => {
    const progressBar = progressBarRef.current;
    // Calculate click position relative to the progress bar
    const clickX = event.clientX - progressBar.getBoundingClientRect().left;
    const newTime = (clickX / progressBar.offsetWidth) * duration;
    audioRef.current.currentTime = newTime;
  };

  const handleScrubberMouseDown = (event) => {
    // Implement logic to enable scrubbing
  };

  return (
      <div className="audio-player-container">


        <div className="track">
          <img src={song.song_image_url} className="songImage" loading="lazy" />

          <div className="track_info">
            <Link class="song_name" href={"track_detail/" + song.track_id}> {song.name}</Link>
            <Link class="artist_name" href={"artist/" + song.artist_id}> {song.artist_name}</Link>
          </div>
        </div>

        <div className="control">
          <div className="controlButtons">
            <button className="shuffleButton" >
              <Shuffle />
            </button>
            <button className="prevButton" >
              <SkipBackFilled />
            </button>
            <button className="playButton" onClick={handlePlayPause}>
              {isPlaying ? <PauseFilled /> : <PlayFilledAlt className="playFilled" />}
            </button>
            <button className="nextButton" onClick={getNextSong}>
              <SkipForwardFilled />
            </button>
            <button className="repeatButton" >
              <Repeat />
            </button>
          </div>
          <p className="current_time"> {convertSecondsToDuration(Math.floor(currentTime))} </p>
          <div ref={progressBarRef} className="progress-container" onClick={handleSeek}>
            <ProgressBar className="progressBar" size="small" value={calculateProgress()} max={1} />
            <div
              className="scrubber"
              ref={scrubberRef}
              style={{ left: `${calculateProgress()}%` }}
              onMouseDown={handleScrubberMouseDown}
            />

          </div>
          <p className="remaining_time"> {convertSecondsToDuration(Math.floor(duration)-Math.floor(currentTime))} </p>



        </div>

        <audio ref={audioRef}></audio>
      </div>

  );
}

export default AudioPlayer;
