import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';
import Layout from './Layout';
import './CreateArtist.css'; 
import artistImage from './images/dannybrown.png'
import { CheckmarkFilled } from '@carbon/icons-react';

import {
  Form,
  FormGroup,
  TextInput,
  RadioButton,
  Button,
  Checkbox,
  RadioButtonGroup,
  Stack,
  Select,
  FormLabel,
  SelectItem,
  DatePicker,
  DatePickerInput,
  MultiSelect,
  NumberInput,
  FileUploaderDropContainer,
  FileUploaderItem,
  Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction, Tabs, TabList, Tab, TabPanels, TabPanel, TextArea, InlineNotification
} from '@carbon/react';

import SongFeed from './SongFeed';
import { useCookies } from 'react-cookie';
import { Amplify } from 'aws-amplify';

function CreateArtist() {
  const { user } = useAuthenticator((context) => [context.user]);

  const [artistName, setArtistName] = useState('');
  const [city, setCity] = useState('');
  const [bio, setBio] = useState('');
  const [newArtistId, setNewArtistId] = useState(null);
  const [cookies] = useCookies(['csrftoken']); // Specify the cookies you're interested in
  const [profileImage, setProfileImage] = useState('');
  const [coverImage, setCoverImage] = useState('');
  // Access the CSRF token directly from the cookies object
  const csrftoken = cookies.csrftoken;
  let navigate = useNavigate();
  
  const handleProfileImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setProfileImage(imageFile);
  };

  const handleCoverImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setCoverImage(imageFile);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submitting now");
    try {
      var form = new FormData();
      form.append('artistName', artistName);
      form.append('city', city);
      form.append('bio', bio);
      if (profileImage) {
        form.append('profileImage', profileImage);
      }
      if (coverImage) {
        form.append('coverImage', coverImage);
      }

      // Get the JWT token from AWS Amplify Auth
      console.log(user);
      const session = await fetchAuthSession();   // Fetch the authentication session

      const token = session.tokens.accessToken.toString();

      // Make the HTTP request using fetch or axios
      const response = await fetch('http://localhost/Apis/create_artist', {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrftoken,  // Use the CSRF token from the cookie
          'Authorization': `Bearer ${token}` // Add the Bearer token
        },
        body: form,
      });

      // Handle the response (e.g., show success message, redirect, etc.)
      const data = await response.json();

      if (response.ok) {
        console.log('Created Artist Successfully');
        navigate('/main/artist/' + data.artist_id);
      } else {
        console.log(response);
        console.error('Upload failed');
      }
    } catch (error) {
      console.error('Error during creation:', error);
    }
  };

  return (
    <div className="ArtistFormContainer">
      <h2 className="artistDetailheader"> Artist Details </h2>
      <Form className="ArtistForm" onSubmit={handleSubmit}>
        <TextInput
          id="artist-name"
          labelText="Artist Name"
          placeholder="Name your artist"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
        />

        <TextInput
          id="location"
          labelText="Base of Operations"
          placeholder="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          size="sm"
        />
        <TextArea
          id="bio"
          labelText="Artist Bio"
          placeholder="Artist Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          rows={4}
          cols={100}
          enableCounter={true}
        />

        <div>
          <b>Profile Image</b>
          <FileUploaderDropContainer
            style={{ color: '#8F7EFB' }}
            innerRef={{
              current: '[Circular]'
            }}
            labelText="Drag and drop files here or click to upload"
            multiple
            name=""
            onAddFiles={handleProfileImageUpload}
            tabIndex={0}
          />
          {profileImage && <FileUploaderItem
            name={profileImage.name}
            labelText={profileImage.name}
            status={'complete'}
          />}
        </div>

        <div>
          <b>Cover Image</b>
          <FileUploaderDropContainer
            style={{ color: '#8F7EFB' }}
            innerRef={{
              current: '[Circular]'
            }}
            labelText="Drag and drop files here or click to upload"
            multiple
            name=""
            onAddFiles={handleCoverImageUpload}
            tabIndex={0}
          />
          {coverImage && <FileUploaderItem
            name={coverImage.name}
            labelText={coverImage.name}
            status={'complete'}
          />}
        </div>

        <Button type="submit" className="bx--btn bx--btn--primary">
          Save
        </Button>
      </Form>
    </div>
  );
}

export default CreateArtist;
