import React, { useState, useEffect } from 'react';
import { Form, TextInput, Button } from '@carbon/react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import './AddSubscriptionTierForm.css';

function AddSubscriptionTierForm() {
  const [tiers, setTiers] = useState([{ name: '', price: '', benefits: '' }]);
  const [cookies] = useCookies(['csrftoken']);
  const { artistId } = useParams(); // Get the artistId from the URL
  const [bearerToken, setBearerToken] = useState(null);

  // Fetch the bearer token from AWS Amplify
  useEffect(() => {
    const fetchBearerToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.accessToken.toString();
        setBearerToken(token);
      } catch (error) {
        console.error("Failed to fetch token", error);
      }
    };

    fetchBearerToken();
  }, []);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newTiers = [...tiers];

    if (name === 'price') {
      let formattedValue = value.replace(/[^0-9.]/g, '');
      const parts = formattedValue.split('.');
      if (parts.length > 2) {
        return;
      }
      if (parts[1] && parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2);
      }
      formattedValue = parts.join('.');
      newTiers[index][name] = formattedValue ? `$${formattedValue}` : '';
    } else {
      newTiers[index][name] = value;
    }

    setTiers(newTiers);
  };

  const addTier = () => {
    setTiers([...tiers, { name: '', price: '', benefits: '' }]);
  };

  const saveTiers = () => {
    const formattedTiers = tiers.map(tier => ({
      ...tier,
      price: tier.price.replace('$', '')
    }));

    var form = new FormData();
    form.append('tiers', JSON.stringify({ tiers: formattedTiers }));
    form.append('artistId', artistId);

    fetch('/Apis/createSubscriptionTiers', {
      method: 'POST',
      headers: {
        'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
        'Authorization': `Bearer ${bearerToken}`, // Add the bearer token to the request headers
      },
      body: form,
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  };

  return (
    <div className="subscriptionTierForm">
      <Form className="subscriptionTierForm">
        {tiers.map((tier, index) => (
          <div key={index}>
            <TextInput
              id={`name-${index}`}
              name="name"
              labelText="Tier Name"
              value={tier.name}
              onChange={e => handleInputChange(index, e)}
            />
            <TextInput
              id={`price-${index}`}
              className="price"
              name="price"
              labelText="Tier Price"
              value={tier.price}
              onChange={e => handleInputChange(index, e)}
              placeholder="$0.00"
            />
            <TextInput
              id={`benefits-${index}`}
              name="benefits"
              labelText="Subscription Benefits"
              value={tier.benefits}
              onChange={e => handleInputChange(index, e)}
            />
          </div>
        ))}
        <div className="buttons">
          <Button onClick={addTier}>Add Tier</Button>
          <Button kind="primary" onClick={saveTiers}>Save</Button>
        </div>
      </Form>
    </div>
  );
}

export default AddSubscriptionTierForm;
