import { ButtonSet, Grid, Column, FormGroup, Stack, Button, TextInput } from '@carbon/react';
import logo from './images/logo.svg'
import { Content, Theme } from '@carbon/react';

import { Routes, Route, BrowserRouter, Link } from 'react-router-dom';
import SignInButton from './Login';
import SignInPage from './SignInPage';
import Main from './Main';
import Home from './Home';
import UploadForm from "./UploadForm";
import ArtistPage from './ArtistPage';
import CreateArtist from "./CreateArtist"
import RegisterPage from './RegisterPage';
import Layout from "./Layout";
import TrackPage from "./TrackPage";
import SongFeed from "./SongFeed";
import Auth from "./Auth";
import MyMediaPage from "./MyMediaPage";
import MyArtistsPage from "./MyArtistsPage";
import CreatePlaylist from "./CreatePlaylist";
import ArtistManagementPage from "./ArtistManagementPage";

import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import awsConfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsConfig);


function App() {
  const handleContextMenu = (e) => {
    //e.preventDefault();
  };

  return (
    <Authenticator socialProviders={['google']}>
      <div className="outerdiv" onContextMenu={handleContextMenu}>
        <BrowserRouter>
          <Routes>
           <Route path="/register" element={<RegisterPage />} />
           <Route path="/login" element={<SignInPage />} />
           <Route path="/main" element={<Layout />}>  
              <Route path="" element={<Main />} />
              <Route path="createArtist" element={<CreateArtist/>} />
              <Route path="artist/:artistId" element={<ArtistPage/>} />
              <Route path="track_detail/:trackId" element={<TrackPage/>} />
              <Route path="EditArtist/:artistId" element={<ArtistManagementPage/>} />
              <Route path="upload" element={<UploadForm/>} />} />
              <Route path="MyMedia" element={<MyMediaPage/>} />
              <Route path="MyArtists" element={<MyArtistsPage/>} />
              <Route path="CreatePlaylist" element={<CreatePlaylist/>} />
           </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </Authenticator>

  );
}

export default App;
