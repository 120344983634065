import React, { useState } from 'react';
import { ButtonSet, Grid, Column, FormGroup, Stack, Button, TextInput, Form, PasswordInput } from '@carbon/react';
import { Routes, Route, BrowserRouter, Link } from 'react-router-dom';
import logo from './images/logo.svg'
import './RegisterPage.css'; 
import hero from './images/hero-1.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your sign-in logic here, e.g., authentication with API or backend
    console.log('Submitting:', email, password);
    console.log(e);
    try {
    // Make the HTTP request using fetch or axios
      const response = await fetch('http://localhost:8000/Apis/register', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({"username": email, "password1": password, "password2": password2}),
      });

    // Handle the response (e.g., show success message, redirect, etc.)
    if (response.ok) {
        console.log('User registered successfully');
        navigate("/");
    } else {
        console.error('Registration failed');
    }
    } catch (error) {
    console.error('Error during registration:', error);
    }
  };
  const handleHomeClick = () => {
    // Add your click event handling logic here
    navigate("/");
  };


  return (
  <div className="registerContainer">

    <div className="registerDiv">
      <img class="homelogo" src={logo} alt="kaze" width="144" onClick={handleHomeClick}/>
      <h2>Sign up</h2>
      <form className="registerForm" onSubmit={handleSubmit}>
          <TextInput
            id="username"
            className="username"
            labelText="Email"
            placeholder="Email Address"
            value={email}
            sz='md'
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="pass1">
            <PasswordInput
              id="password"
              labelText="Password"
              placeholder="Password"
              sz='md'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
     
            />
          </div>
          <div className="pass2">
            <PasswordInput
              id="password"
              labelText="Password"
              placeholder="Enter Password Again"
              sz='md'
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </div>
      </form>
      <Button className="submitButton" onClick={handleSubmit} kind="primary" size="sm">Sign up</Button>

    <footer className="register-footer-container"> 
      ©2024 Kaze. All Rights Reserved.
      <div className="footer-links">
        <a className="privacy-policy" href="">Privacy Policy</a>
        <a className="terms-of-service" href="/Register">Terms of Service</a>
        <a className="contact-us" href="/Register">Contact us</a>
      </div>
    </footer>
    </div>



  </div>

  );
}


export default RegisterPage;